import { ReactAutolinker } from '@monorepo/shared/componentsV2/ReactAutolinker';
import React from 'react';

interface Props {
  value: string;
}
export const TextTableCell = (props: Props) => {
  const { value } = props;
  return (
    <span>
      <ReactAutolinker textToLink={value} />
    </span>
  );
};
